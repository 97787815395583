import React, { useState } from "react";
import Sidebar from "../sidebar";
import Header from "../header";
import { useNavigate } from "react-router-dom";

export default function Setting() {
  const navigate = useNavigate();
  const [showSetting, setShowSetting] = useState({
    account: true,
    security: false,
    notification: false
  });

  function handleSettingRoute(type) {
    switch (type) {
      case "ACCOUNT": setShowSetting({
        account: true,
        security: false,
        notification: false
      });
        break;
      case "SECURITY": setShowSetting({
        account: false,
        security: true,
        notification: false
      });
        break;
      case "NOTIFICATION": setShowSetting({
        account: false,
        security: false,
        notification: true
      });
        break;
    }
  }

  return (
    <>
      <Sidebar />
      <section class="home-section">
        <Header />
        <div class="account_section">
          <div class="container">
            <div class="inner_header">
              <h2>Setting</h2>
              <h4 class="Main_bg_title">Setting</h4>
            </div>
            <div class="account_page_row">
              <div class="account_type">
                <div class="acount_div">
                  <h3 class={showSetting.account && "active"} onClick={() => handleSettingRoute("ACCOUNT")}>
                    <i class="fa fa-user-circle-o" aria-hidden="true">
                    </i> Account
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </h3>
                  <p>Find your username, account number and purchase history, edit your email address and
                    more.</p>
                </div>
                <div class="acount_div">
                  <a href="#">
                    <h3 class={showSetting.security && "active"} onClick={() => handleSettingRoute("SECURITY")}>
                      <i class="fa fa-lock" aria-hidden="true"></i>
                      Security & Privacy
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </h3>
                  </a>
                  <p>Add features like Two-factor authentication, review our privacy policy, edit your
                    password and more.</p>
                </div>
                <div class="acount_div">
                  <a href="#">
                    <h3 class={showSetting.notification && "active"} onClick={() => handleSettingRoute("NOTIFICATION")}>
                      <i class="fa fa-bell" aria-hidden="true"></i>
                      Notifications
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </h3>
                  </a>
                  <p>Choose which email notifications and updates you want to receive.</p>
                </div>
              </div>
              {showSetting.account &&
                <div class="account_info">
                  <h2>Account</h2>
                  <div class="user_account_details">
                    <h4>Username</h4>
                    <p>montysingh</p>
                  </div>

                  <div class="user_account_details">
                    <h4>Profile Number</h4>
                    <p>110492622</p>
                  </div>

                  <div class="user_account_details">
                    <h4>Gender</h4>
                    <p>Male</p>
                  </div>

                  <div class="user_account_details">
                    <h4>Email Address <span class="Unverified">Unverified <i class="fa fa-chevron-right"
                      aria-hidden="true"></i></span></h4>
                    <p>qxxxxxxxx@mailinator.com</p>
                  </div>

                  <div class="user_account_details">
                    <a href="#">
                      <h4>Password <i class="fa fa-chevron-right" aria-hidden="true"></i></h4>
                    </a>
                  </div>

                  <div class="user_account_details">
                    <a href="#">
                      <h4>Zip/Postal Code <i class="fa fa-chevron-right" aria-hidden="true"></i></h4>
                    </a>
                    <p>140301</p>
                  </div>

                  <div class="user_account_details">
                    <a href="#">
                      <h4>Language <i class="fa fa-chevron-right" aria-hidden="true"></i></h4>
                    </a>
                    <p>English</p>
                  </div>

                  <div class="user_account_details">
                    <h4>Show Location in Search <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>

                  <div class="user_account_details">
                    <h4>View Explicit Photos <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>
                  <div class="user_account_details">
                    <h4>Send All Messages Priority <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>

                  <div class="user_account_details border_row">
                    <a href="#">
                      <h4>Send All Messages Priority <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      </h4>
                    </a>
                  </div>

                  <div class="user_account_details ">
                    <a href="#">
                      <h4>Deactivate Account <i class="fa fa-chevron-right" aria-hidden="true"></i></h4>
                    </a>
                  </div>
                  <div class="user_account_details ">
                    <a href="#">
                      <h4>Delete Account <i class="fa fa-chevron-right" aria-hidden="true"></i></h4>
                    </a>
                  </div>
                </div>
              }
              {showSetting.security &&
                <div class="account_info">
                  <h2>Security & Privacy</h2>
                  <div class="verification">
                    <p><b>2-Step Verification</b> <i class="fa fa-chevron-right" aria-hidden="true"></i></p>
                    <p>Protect your account with an extra layer of security. Once turned on, you will need to
                      enter both a password and an authentication code from your mobile device to sign in to
                      your account.</p>
                  </div>
                  <div class="TwoFactor"><p>Status: <span>Off</span></p></div>
                  <div class="user_account_details">
                    <a href="#">
                      <h4>Password <i class="fa fa-chevron-right" aria-hidden="true"></i></h4>
                    </a>
                  </div>
                  <div class="user_account_details">
                    <a href="#">
                      <h4>Security Question <i class="fa fa-chevron-right" aria-hidden="true"></i></h4>
                    </a>
                  </div>
                  <div class="user_account_details">
                    <a href="#">
                      <h4>Blocked Profiles <i class="fa fa-chevron-right" aria-hidden="true"></i></h4>
                    </a>
                  </div>

                  <div class="user_account_details">
                    <a href="#">
                      <h4>Private Keys Sent<i class="fa fa-chevron-right" aria-hidden="true"></i></h4>
                    </a>
                  </div>
                  <div class="user_account_details">
                    <h4>Auto Private Key Exchange <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>
                  <div class="user_account_details">
                    <h4>Show me as OFFLINE when I am Logged In <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>
                  <div class="user_account_details">
                    <h4>Hide Profile <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>
                  <div class="user_account_details border_row">
                    <a href="#">
                      <h4>Security & Privacy Tips <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      </h4>
                    </a>
                  </div>
                </div>
              }
              {showSetting.notification &&
                <div class="account_info">
                  <h2>Notifications</h2>
                  <div class="verification">
                    <p><b>Send me an email when...</b> </p>
                  </div>

                  <div class="user_account_details">
                    <h4>I have New Mailbox activity <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>

                  <div class="user_account_details">
                    <h4>A Favorite Member logs in <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>
                  <div class="user_account_details">
                    <h4>Someone viewed my profile <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>

                  <div class="user_account_details">
                    <h4>New Members sign up in my area <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>

                  <div class="user_account_details">
                    <h4>Marketing and Special Offers <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>

                  <div class="user_account_details">
                    <h4>Quick Reply messages <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                    <p>Automatic messages sent in response to Winks.</p>
                  </div>

                  <div class="verification">
                    <p><b>I also want to receive...</b> </p>
                  </div>

                  <div class="user_account_details">
                    <h4>New Local Match messages <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                    <p>Lets you know about potential matches you might be interested in.</p>
                  </div>

                  <div class="user_account_details">
                    <h4>Show in-app notifications <label class="switch">
                      <input type="checkbox" id="toggleSwitch" />
                      <span class="slider round"></span>
                    </label> </h4>
                  </div>

                  <div class="user_account_details border-top">
                    <p>Note: You may opt in or out of Email Notifications at any time. Depending on your level
                      of activity, the number of emails you receive may vary from one per week to several per
                      day.</p>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}