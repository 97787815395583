/* Library */
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
/* Layout File */
import Welcome from "./layout/welcome.jsx";
import Dashboard from "./layout/dashboard.jsx";
import Chat from "./component/chat/index.jsx";
import Pricing from "./component/pricing/index.jsx";
import Setting from "./component/setting/index.jsx";
import Profile from "./component/profile/index.jsx";
/* Components File */
import Login from "./component/login&signup/index.jsx";
/* ProtectedRoute Component */
import ProtectedRoute from './protectedRoute.js';
/* Style File */
import './global.css';

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Welcome />} />
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <ProtectedRoute>
              <Pricing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/setting"
          element={
            <ProtectedRoute>
              <Setting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        {/* Catch-All Route to Redirect Undefined Paths to "/" */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
