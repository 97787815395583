import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainLOGO } from "../../assets/images";

export default function Header() {
    const navigate = useNavigate();
    const [currentRoute, setCurrentRoute] = useState("");

    useEffect(()=>{
        let currentPathname = window.location.pathname;
        setCurrentRoute(currentPathname)
    }, [])

    return (
        <>
            <nav>
                <div className="navbar_header">
                    <div className="inner_var_Bar">
                        <img src={MainLOGO} alt="" />

                        <div className="manu_right">
                            <ul>
                                
                                <li>
                                    <a href="" onClick={() => navigate('/profile')} className={currentRoute == "/profile" ? "active" : ""}>PROFILE</a>
                                </li>
                                <li>
                                    <a href="" onClick={() => navigate('/dashboard')}  className={currentRoute == "/dashboard" ? "active" : ""}>DISCOVER</a>
                                </li>
                                <li>
                                    <a href="" onClick={() => navigate('/chat')}  className={currentRoute == "/chat" ? "active" : ""}>MESSAGES</a>
                                </li>

                                <li>
                                    <a href="" onClick={() => navigate('/pricing')} className={`Button_credit`}>BUY CREDITS</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}