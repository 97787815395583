import React from "react";
import { useNavigate } from "react-router-dom";

export default function Filter({ showFilter, setFilter }) {
    const navigate = useNavigate();

    function handleAccordian() {
        const headers = document.querySelectorAll('.accordion-header');

        headers.forEach(header => {
            header.addEventListener('click', () => {
                const content = header.nextElementSibling;

                // If this content is already open, close it
                if (content.style.display === "block") {
                    content.style.display = "none";
                    header.classList.remove('active-header');
                } else {
                    // Close all other sections
                    document.querySelectorAll('.accordion-content').forEach(item => item.style.display = 'none');
                    document.querySelectorAll('.accordion-header').forEach(h => h.classList.remove('active-header'));

                    // Open the clicked section
                    content.style.display = "block";
                    header.classList.add('active-header');
                }
            });
        });

        // Open the first tab by default
        document.querySelector('.accordion-content').style.display = 'block';
    }

    return (
        <>
            {showFilter && <div class="filter_modal">
                <div class="filter_card">
                    <div class="inner_content_filter">
                        <div class="main_content_edits">
                            <h3>Search Filters <i class="fa fa-times" aria-hidden="true" onClick={() => setFilter(false)}></i></h3>

                            <div class="accordion">
                                <div class="accordion-item">
                                    <div class="accordion-header" onClick={handleAccordian}>Section 1</div>
                                    <div class="accordion-content">

                                        <div class="age-range-container">
                                            <div class="profile_points">
                                                <h5>Age Range <span>22 - 43</span></h5>
                                                <div class="text_input_row">
                                                    <input type="range" id="ageRange" min="18" max="100" value="27" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="change_location">
                                            <div class="laction_row">
                                                <h6>Search Location</h6>
                                                <p>Rupnagar, PB - IN</p>
                                            </div>
                                            <svg class="MuiSvgIcon-root icon jss5 jss192 LocationIconStyled-sc-zdwxw5-1 gdwsMn"
                                                focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                <path
                                                    d="M12,8 C9.79,8 8,9.79 8,12 C8,14.21 9.79,16 12,16 C14.21,16 16,14.21 16,12 C16,9.79 14.21,8 12,8 Z M20.94,11 C20.48,6.83 17.17,3.52 13,3.06 L13,1 L11,1 L11,3.06 C6.83,3.52 3.52,6.83 3.06,11 L1,11 L1,13 L3.06,13 C3.52,17.17 6.83,20.48 11,20.94 L11,23 L13,23 L13,20.94 C17.17,20.48 20.48,17.17 20.94,13 L23,13 L23,11 L20.94,11 Z M12,19 C8.13,19 5,15.87 5,12 C5,8.13 8.13,5 12,5 C15.87,5 19,8.13 19,12 C19,15.87 15.87,19 12,19 Z">
                                                </path>
                                            </svg>
                                        </div>

                                        <div class="age-range-container">
                                            <div class="profile_points">
                                                <h5>SEARCH WITHIN <span>68KM (42MI)</span></h5>
                                                <div class="text_input_row">
                                                    <input type="range" id="ageRange" min="18" max="100" value="27" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="change_location">
                                            <div class="laction_row">
                                                <h6>Search by Last Login
                                                </h6>
                                                <p>Anytime</p>
                                            </div>
                                            <i class="fa fa-sort" aria-hidden="true"></i>

                                        </div>

                                        <div class="change_location">
                                            <div class="laction_row">
                                                <h6>Search Location</h6>
                                                <p>Rupnagar, PB - IN</p>
                                            </div>
                                            <i class="fa fa-sort" aria-hidden="true"></i>

                                        </div>

                                        <div class="user_account_details">
                                            <h4>Has Private Photos <label class="switch">
                                                <input type="checkbox" id="toggleSwitch" />
                                                <span class="slider round"></span>
                                            </label> </h4>
                                        </div>

                                        <div class="user_account_details">
                                            <h4>Has Public Photos <label class="switch">
                                                <input type="checkbox" id="toggleSwitch" />
                                                <span class="slider round"></span>
                                            </label> </h4>
                                        </div>

                                        <div class="user_account_details">
                                            <h4>Has Public Photos <label class="switch">
                                                <input type="checkbox" id="toggleSwitch" />
                                                <span class="slider round"></span>
                                            </label> </h4>
                                        </div>

                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" onClick={handleAccordian}>Section 2</div>
                                    <div class="accordion-content">

                                        <div class="change_location">
                                            <div class="laction_row">

                                                <p>Body Type</p>
                                            </div>
                                            <i class="fa fa-sort" aria-hidden="true"></i>
                                        </div>

                                        <div class="age-range-container">
                                            <div class="profile_points">
                                                <h5>HEIGHT <span>Any - 2.15m</span></h5>
                                                <div class="text_input_row">
                                                    <input type="range" id="ageRange" min="18" max="100" value="27" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="change_location">
                                            <div class="laction_row">
                                                <p>language</p>
                                            </div>
                                            <i class="fa fa-sort" aria-hidden="true"></i>
                                        </div>

                                        <div class="change_location">
                                            <div class="laction_row">
                                                <p>Limits</p>
                                            </div>
                                            <i class="fa fa-sort" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="footer_btns_modal">
                            <button class="reset">Reset</button>
                            <button class="apply">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}