import React from "react";
import { useNavigate } from "react-router-dom";
import { AndriodStore, AppleStore, MainLOGO, Pixel1234, PixelAnnetnavi, PixelCottonBro, PixelIntrospective, PixelShcets, user } from '../assets/images/index';

export default function Welcome() {
    const navigate = useNavigate();
    return (
        <div className="main-section">
            <section>
                <div class="container-fluid">
                    <div class="main_section_dating">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="inner_section_dating">
                                        <a href="" onClick={()=> navigate('/login')} class="login"> Login</a>
                                        <img src={MainLOGO} alt="" />
                                        <h2>Dating for Grown-Ups: Build Authentic Connections and Embrace Commitment </h2>
                                        <p> Meet Like-Minded Individuals Who Value Open and Discreet Relationships.</p>
                                        <a href="" onClick={()=> navigate('/login')}>See your matches <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                            viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
                                            <path d="m600-200-57-56 184-184H80v-80h647L544-704l56-56 280 280-280 280Z" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="store_links_images">
                            <img src={AppleStore} alt="" />
                            <img src={AndriodStore} alt="" />
                        </div> */}
                    </div>
                </div>
            </section>
            <section>
                <div class="dating_connection">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="daing_content">
                                    <h3>Millions of people just like you are looking for a discreet connection</h3>
                                    <p>Every day, people just like you join the Ashley Madison® network to find discreet
                                        relationships of all kinds. Married, attached, looking to explore, or just curious
                                        to discover what’s out there – Ashley Madison is the leading discreet, like-minded
                                        dating community in the world.</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="dating_connection_img">
                                    <img src={PixelCottonBro} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="dating_connection_img">
                                    <img src={PixelAnnetnavi} alt="" />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="daing_content">
                                    <h3>When monogamy becomes monotony™</h3>
                                    <p>Whatever brings you to Ashley Madison, you can feel safe knowing that you’re not
                                        alone. Our members are attached and seeking a connection. Many are attached and
                                        seeking something like-minded or polyamory. They are here looking for online
                                        flirtation, for something exciting, for someone with a mutual feeling. They are
                                        students, married people, singles, office workers, tradespeople, entrepreneurs,
                                        retirees, and everyone in between.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="daing_content">
                                    <h3>Rooted in desire</h3>
                                    <p>Relationships are a nuanced topic and everyone has different reasons for joining a dating
                                        site like Ashley Madison. Our members are united in a desire to seek out real, discreet
                                        connections and Ashley Madison gives you a platform to search for what's missing.</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="dating_connection_img">
                                    <img src={PixelIntrospective} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="dating_connection_img">
                                    <img src={Pixel1234} alt="" />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="daing_content">
                                    <h3>The first married dating website</h3>
                                    <p>When Ashley Madison started in 2001, there weren't many places adults could go for
                                        married dating. Meeting someone at work or through friends is too risky when discretion
                                        is your number one concern and the old taboos are frowned upon. Many turned to
                                        traditional dating websites, but found it difficult to connect with people looking for a
                                        similar type of arrangement. Therefore, Ashley Madison was created as the first website
                                        that was open and honest about what you could find: like-minded people looking for
                                        married dating.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="daing_content">
                                    <h3>Get the Married Dating App</h3>
                                    <p>We know this is an important decision for you. Whether you’re escaping a sexless
                                        marriage,
                                        or seeking a mutual relationship that demands sensitivity and privacy, online dating is
                                        the way
                                        to go. Our state of the art affair dating app is designed with YOU and your privacy in
                                        mind.
                                        Connect, experience married dating, and create your own story.</p>

                                    <div class="store_links">
                                        <img src={AppleStore} alt="" />
                                        <img src={AndriodStore} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="dating_connection_img">
                                    <img src={PixelShcets} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="Our_Story_section">
                    <div class="inner_story_row">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="our_content_col">
                                        <h3>Our Story</h3>
                                        <p>As a place free of judgement, Ashley Madison revolves around the idea that consenting
                                            adults
                                            should be able to do whatever they want in private.What began over a decade ago soon
                                            grew to be the international leader in the married dating space. Originally designed
                                            specifically for married women and men looking to have an affair in the most
                                            discreet way possible, it has since evolved to be so much more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="testimonial-slider">
                                <div class="testimonial active">
                                    <div class="image_col">
                                        <img src={user} alt="" />
                                    </div>
                                    <div class="cotent_col_slider">
                                        <h4>Member Experiences</h4>
                                        <p>"I joined Ashley Madison and have found the fulfillment I
                                            needed to return to my husband a satisfied partner. It’s
                                            completely removed the anxiety from our marriage."</p>
                                        <span>- Female, 40s, Canada</span>
                                    </div>
                                </div>
                                <div class="testimonial">
                                    <div class="image_col">
                                        <img src={user} alt="" />
                                    </div>
                                    <div class="cotent_col_slider">
                                        <h4>Member Experiences</h4>
                                        <p>"I joined Ashley Madison and have found the fulfillment I
                                            needed to return to my husband a satisfied partner. It’s
                                            completely removed the anxiety from our marriage."</p>
                                        <span>- Female, 40s, Canada</span>
                                    </div>
                                </div>
                                <div class="testimonial">
                                    <div class="image_col">
                                        <img src={user} alt="" />
                                    </div>
                                    <div class="cotent_col_slider">
                                        <h4>Member Experiences</h4>
                                        <p>connecting with a man of a different culture or someone taller or much older -
                                            something that my husband will never be, it is all okay."</p>
                                        <span>- Female, 40s, Canada</span>
                                    </div>
                                </div>
                                <div class="dots">
                                    <span class="dot active" data-index="0"></span>
                                    <span class="dot" data-index="1"></span>
                                    <span class="dot" data-index="2"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="accodian_main_section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="accordion" id="accordionExample">
                                    <h3>FAQS</h3>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Accordion Item #1
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p> It is shown by
                                                    default, until the collapse plugin adds the appropriate classes that we use to
                                                    style each element. These classes control the overall appearance, as well as the
                                                    showing and hiding via CSS transitions. You can modify any of this with custom
                                                    CSS or overriding our default variables. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Accordion Item #2
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p> It is shown by
                                                    default, until the collapse plugin adds the appropriate classes that we use to
                                                    style each element. These classes control the overall appearance, as well as the
                                                    showing and hiding via CSS transitions. You can modify any of this with custom
                                                    CSS or overriding our default variables. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                                Accordion Item #3
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p> It is shown by
                                                    default, until the collapse plugin adds the appropriate classes that we use to
                                                    style each element. These classes control the overall appearance, as well as the
                                                    showing and hiding via CSS transitions. You can modify any of this with custom
                                                    CSS or overriding our default variables. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapseThree">
                                                Accordion Item #4
                                            </button>
                                        </h2>
                                        <div id="collapse4" class="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p> It is shown by
                                                    default, until the collapse plugin adds the appropriate classes that we use to
                                                    style each element. These classes control the overall appearance, as well as the
                                                    showing and hiding via CSS transitions. You can modify any of this with custom
                                                    CSS or overriding our default variables. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="view_btn"><a href="#" class="view_more">View More</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div class="footer_section">
                    <div class="footer_logo">
                        <img src={MainLOGO} alt="" />
                    </div>
                    <div class="footer_links">
                        <div class="store_links">
                            <img src={AppleStore} alt="" />
                            <img src={AndriodStore} alt="" />
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}