import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../component/header/index.js";
import Sidebar from "../component/sidebar/index.jsx";
import Filter from "../component/filter/index.jsx";
import Modal from "../component/modal/index.jsx";
import {
  MainLOGO,
  Profile001,
  Profile003,
  Profile004,
  Profile005,
  Profile006,
  Profile007,
  Profile008,
  Profile009,
} from "../assets/images/index.js";

export default function Dashboard() {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />
        <div className="container">
          <div className="row">
            <div className="inner_header">
              <h2>
                Discover{" "}
                <i
                  className="fa fa-sliders"
                  aria-hidden="true"
                  onClick={() => setShowFilter(true)}
                ></i>
              </h2>
              <h4 className="Main_bg_title">Discover</h4>
            </div>
            <div className="profile_Cards_row">
              <div className="profile_card">
                <img src={Profile001} alt="" />
                <div className="profile_data">
                  <h5>Person name</h5>
                  <p>Location</p>
                  <div className="review_person">
                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>

              <div className="profile_card">
                <img src={Profile003} alt="" />
                <div className="profile_data">
                  <h5>Person name</h5>
                  <p>Location</p>
                  <div className="review_person">
                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>

              <div className="profile_card">
                <img src={Profile004} alt="" />
                <div className="profile_data">
                  <h5>Person name</h5>
                  <p>Location</p>
                  <div className="review_person">
                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>

              <div className="profile_card">
                <img src={Profile005} alt="" />
                <div className="profile_data">
                  <h5>Person name</h5>
                  <p>Location</p>
                  <div className="review_person">
                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>

              <div className="profile_card">
                <img src={Profile009} alt="" />
                <div className="profile_data">
                  <h5>Person name</h5>
                  <p>Location</p>
                  <div className="review_person">
                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>

              <div className="profile_card">
                <img src={Profile008} alt="" />
                <div className="profile_data">
                  <h5>Person name</h5>
                  <p>Location</p>
                  <div className="review_person">
                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>

              <div className="profile_card">
                <img src={Profile007} alt="" />
                <div className="profile_data">
                  <h5>Person name</h5>
                  <p>Location</p>
                  <div className="review_person">
                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>

              <div className="profile_card">
                <img src={Profile006} alt="" />
                <div className="profile_data">
                  <h5>Person name</h5>
                  <p>Location</p>
                  <div className="review_person">
                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Filter showFilter={showFilter} setFilter={setShowFilter} />
      <Modal showModal={showModal} setModal={setShowModal} />
    </>
  );
}
