import React from "react";
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
    const navigate = useNavigate();

    function menuBtnChange() {
        let sidebar = document.querySelector(".sidebar");
        let closeBtn = document.querySelector("#btn");
        if (sidebar.classList.contains("open")) {
            closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
        } else {
            closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");//replacing the iocns class
        }
    }

    function handleClick(TYPE) {
        let sidebar = document.querySelector(".sidebar");
        let closeBtn = document.querySelector("#btn");
        switch (TYPE) {
            case 'USERNAME':
                sidebar.classList.toggle("open");
                menuBtnChange();
                break;
            case 'SEARCH':
                sidebar.classList.toggle("open");
                menuBtnChange();
                break;
            case 'SETTING':
                navigate('/setting')
                break;
        }
    }

    function handleLogOut(){
        try {
            console.log('33');
            localStorage.removeItem('user');
            navigate('/')
        } catch (error) {
            
        }
    }

    return (
        <>
            <div className="sidebar" onClick={() => handleClick("SIDEBAR")}>
                <div className="logo-details">
                    <div className="logo_name">User Name</div>
                    <i className="fa fa-bars" aria-hidden="true" id="btn" onClick={() => handleClick("USERNAME")}></i>

                </div>
                <ul className="nav-list">
                    <li>
                        <i className="fa fa-search" aria-hidden="true" onClick={() => handleClick("SEARCH")}></i>
                        <input type="text" placeholder="Search..." />
                        <span className="tooltip">Search</span>
                    </li>
                    <li>
                        <a href="#">
                            <i className="fa fa-heart" aria-hidden="true"></i>
                            <span className="links_name">Favorites</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="fa fa-eye" aria-hidden="true"></i>
                            <span className="links_name">Viewed Me</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" onClick={() => handleClick("SETTING")}>
                            <i className="fa fa-cog" aria-hidden="true"></i>
                            <span className="links_name" >Setting</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                            <span className="links_name">Help</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" onClick={handleLogOut}>
                            <i className="fa fa-sign-out" aria-hidden="true"></i>
                            <span className="links_name">Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}