import React, { useState } from "react";
import Sidebar from "../sidebar";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Profile() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        email: "",
        name: "",
        address: "",
        images: [],
        greeting: "",
        about: "",
        vital: "",
        dob: new Date(),
        weight: 32,
        age: 32,
        language: ""
    });

    async function getUser(){
        try {
            
        } catch (error) {
            
        }
    }

    return (
        <>
            <Sidebar />
            <section class="home-section">
                <Header />
                <div class="profile_section">
                    <div class="container">

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner_section_profile">
                                    <a href="" class="back_button" onClick={()=> navigate('/dashboard')}><i class="fa fa-arrow-left" aria-hidden="true"></i>
                                        BACK</a>

                                    <div class="profile_user_data">
                                        <div class="profile_upload_image">
                                            <div class="inner_form">
                                                <p>Profiles with photos get a better response rate.</p>
                                                <label for="upload">Add a Photo</label>
                                                <input type="file" id="upload" />
                                            </div>
                                        </div>
                                        <div class="user_profile">
                                            <div class="user_name">
                                                <h3>montysingh <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                </h3>
                                                <p>34 • Rupnagar, Punjab, India</p>
                                            </div>

                                            <div class="profile_links">
                                                <div class="svg_icon_div">
                                                    <svg class="MuiSvgIcon-root icon jss5 jss32" focusable="false"
                                                        viewBox="0 0 24 24" aria-hidden="true">
                                                        <path
                                                            d="M4,3 L4,0 L6,0 L6,3 L9,3 L9,5 L6,5 L6,8 L4,8 L4,5 L1,5 L1,3 L4,3 Z M7,9 L7,6 L10,6 L10,3 L17,3 L18.83,5 L22,5 C23.1,5 24,5.9 24,7 L24,19 C24,20.1 23.1,21 22,21 L6,21 C4.9,21 4,20.1 4,19 L4,9 L7,9 Z M14,18 C16.76,18 19,15.76 19,13 C19,10.24 16.76,8 14,8 C11.24,8 9,10.24 9,13 C9,15.76 11.24,18 14,18 Z M10.8,13 C10.8,14.77 12.23,16.2 14,16.2 C15.77,16.2 17.2,14.77 17.2,13 C17.2,11.23 15.77,9.8 14,9.8 C12.23,9.8 10.8,11.23 10.8,13 Z">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <i class="fa fa-ellipsis-h" aria-hidden="true"></i>

                                            </div>

                                            <div class="profile_points">
                                                <h5>Greeting <span>Edit</span></h5>
                                                <div class="text_input_row">
                                                    Hello
                                                    <textarea name="text-lines" id="text-lines"></textarea>
                                                </div>
                                            </div>

                                            <div class="profile_points">
                                                <h5>ABOUT ME <span>Edit</span></h5>
                                                <div class="text_input_row">
                                                    Tell us about yourself
                                                    <textarea name="text-lines" id="text-lines"></textarea>
                                                </div>
                                            </div>

                                            <div class="profile_points">
                                                <h5>PHOTO GALLERY <span>Edit</span></h5>
                                                <div class="images_upload_section">
                                                    <img src="" alt="" />
                                                    <div class="inner_form_upload">
                                                        <label for="add"> <i class="fa fa-camera" aria-hidden="true"></i>
                                                            Add</label>
                                                        <input type="file" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="profile_points">
                                                <h5>VITALS <span>Edit</span></h5>
                                                <div class="VITALS_status">
                                                    <p><svg fill="#000000" width="64px" height="64px" viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg">

                                                        <g id="SVGRepo_bgCarrier" stroke-width="0" />

                                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                            stroke-linejoin="round" />

                                                        <g id="SVGRepo_iconCarrier">

                                                            <path
                                                                d="M3.5,13.5A8.5,8.5,0,1,0,14.354,5.339L15.5,3.556,14.625,2H9.375L8.5,3.556,9.646,5.339A8.509,8.509,0,0,0,3.5,13.5ZM12,7a6.5,6.5,0,1,1-6.5,6.5A6.508,6.508,0,0,1,12,7Z" />
                                                        </g>

                                                    </svg> Single Male seeking Females</p>
                                                    <p><i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                                        Undecided</p>
                                                    <p><i class="fa fa-birthday-cake" aria-hidden="true"></i>
                                                        34 (Pisces)</p>
                                                </div>


                                                <div class="vitals_form">
                                            <h6 class="title">WHY DO WE ASK THIS?</h6>
                                            <div class="inner_forms">
                                                <div class="change_location">
                                                    <div class="laction_row">
                                                        <h6>relationshipStatus</h6>
                                                        <p>Single Male seeking Females</p>
                                                    </div>
                                                    <i class="fa fa-sort" aria-hidden="true"></i>
                                                </div>

                                                <div class="change_location">
                                                    <div class="laction_row">
                                                        <p>bodyType</p>
                                                    </div>
                                                    <i class="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </div>

                                            <h6 class="title">Date of Birth</h6>
                                            <div class="inner_forms">
                                                <div class="change_location">
                                                    <div class="laction_row">
                                                        <h6>Day</h6>
                                                        <p>3</p>
                                                    </div>
                                                    <i class="fa fa-sort" aria-hidden="true"></i>
                                                </div>

                                                <div class="change_location">
                                                    <div class="laction_row">
                                                        <h6>Month</h6>
                                                        <p>March</p>
                                                    </div>
                                                    <i class="fa fa-sort" aria-hidden="true"></i>
                                                </div>


                                                <div class="change_location">
                                                    <div class="laction_row">
                                                        <h6>Day</h6>
                                                        <p>3</p>
                                                    </div>
                                                    <i class="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </div>


                                            <div class="inner_forms">
                                                <div class="change_location">
                                                    <div class="laction_row">
                                                        <h6>weight</h6>
                                                        <p>Ask me</p>
                                                    </div>
                                                    <i class="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </div>

                                            <div class="inner_forms">
                                                <div class="change_location">
                                                    <div class="laction_row">
                                                        <p>Languages Spoken</p>
                                                    </div>
                                                    <i class="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </div>

                                            <div class="btn_row_choise">
                                                <button class="Cancel">Cancel</button>
                                                <button class="Save">Save</button>
                                            </div>

                                        </div>



                                        
                                            </div>
                                            <div class="profile_points">
                                                <h5>MY INTERESTS & DESIRES <span>Edit</span></h5>
                                                <div class="text_input_row">
                                                    What are you into?
                                                    <textarea name="text-lines" id="text-lines"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}