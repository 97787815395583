import React from "react";
import { useNavigate } from "react-router-dom";

export default function Modal({ showModal, setModal }) {
    const navigate = useNavigate();

    return (
        <>
            {showModal && <div class="ModalContentStyle">
                <div class="ModalContentScrollingWrapper">
                    <i class="fa fa-times" aria-hidden="true" onClick={() => setModal(false)}></i>
                    <h4 class="ModalHeader">Relationship
                        status</h4>
                    <p class="ModalTextStyle"><span>Looking for
                        something adventurous or safe? Choose your partner’s relationship status.</span></p>
                    <div class="choose_options">
                        <div class="choose_choise">
                            <input type="checkbox" />
                            <h5>Attached Female seeking Males</h5>
                        </div>

                        <div class="choose_choise">
                            <input type="checkbox" />
                            <h5> Single Female seeking Males</h5>
                        </div>
                    </div>

                    <div class="btn_row_choise">
                        <button class="Cancel">Cancel</button>
                        <button class="Save">Save</button>
                    </div>
                </div>
            </div>
            }
        </>
    )

}