import React from "react";
import { useNavigate } from "react-router-dom";

export default function PaymentDetail({paymentPlan, paymentData, resetSelectedPlan}) {
    const navigate = useNavigate();

    return (
        <>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner_section_profile">
                            <a href="#" class="back_button" onClick={()=>resetSelectedPlan(null)} ><i class="fa fa-arrow-left" aria-hidden="true"></i>
                                BACK</a>
                        </div>
                        <div class="pay_options">
                            <div class="credit_card">
                                <i class="fa fa-credit-card-alt" aria-hidden="true"></i> Credit
                            </div>
                            <div class="paypal">
                                <i class="fa fa-paypal" aria-hidden="true"></i> Paypal
                            </div>
                        </div>
                        <div class="top_credit">
                            <input type="checkbox" />
                            <div class="credit_text">
                                <h5>Top up your credits</h5>
                                <p>Check here to top up your credits when you run out so that you are ready for any encounter.
                                </p>
                            </div>
                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                        </div>
                        <div class="cart_section">
                            <p>1000 credits <b>₹3,999.00</b></p>
                            <div class="product-name">
                                <div>
                                    <p>Mobile Access</p>
                                    <span>(One-time activation fee)</span>
                                </div>
                                <p><b>₹99.00</b></p>
                            </div>

                            <p>Member Initiated Contact <b>₹0.00</b></p>

                            <div class="total_amount">
                                <h4>Total <b>₹4,098.00*</b></h4>
                            </div>
                        </div>
                        <p class="footer_terms">*All Prices in Indian Rupee. This purchase is governed by our current Terms and
                            Conditions The link will open in a new window and Privacy Policy The link will open in a new window.
                        </p>

                        <div class="paypal_checkout_btn">
                            <button><i class="fa fa-cc-paypal" aria-hidden="true"></i>
                                Checkout </button>
                        </div>

                        <div class="top_credit">
                            <input type="checkbox" />
                            <div class="credit_text">
                                <h5>Include 30 days of FREE Member Initiated Contact (MIC)
                                </h5>
                                <p>(Regular ₹399.00/month thereafter)
                                </p>
                            </div>
                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}