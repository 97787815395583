import React, { useState } from "react";
import Sidebar from "../sidebar";
import Header from "../header";
import PaymentDetail from "../payment-detail";
import { useNavigate } from "react-router-dom";

export default function Pricing() {
  const navigate = useNavigate();
  const [selectedPaymentPlan, setSelectedpaymentPlan] = useState(null);
  const [selectedPaymentData, setSeldectedpaymentData] = useState(null);

  function handleSelection(type) {
    switch (type) {
      case "ELITE": setSelectedpaymentPlan("Elite");
        setSeldectedpaymentData({
          name: "elite",
          price: 11.99
        });
        break;
      case "CLASSIC": setSelectedpaymentPlan("Classic");
        setSeldectedpaymentData({
          name: "classic",
          price: 21.99
        });
        break;
      case "BASIC": setSelectedpaymentPlan("Basic");
        setSeldectedpaymentData({
          name: "basic",
          price: 31.99
        });
        break;
    }
  }

  return (
    <>
      <Sidebar />
      <section class="home-section">
        <Header />
        {selectedPaymentPlan == null && <div class="container">
          <div class="plans__tab-item tab_item">
            <div class="inner_header">
              <h2>Plans </h2>
              <h4 class="Main_bg_title">Plans</h4>
              <p>Choose your package</p>
            </div>

            <div class="plans-tab">
              <div class="plans-item">
                <div class="plans-item__content">
                  <div class="plans-item__name">Elite</div>
                  <div class="plans-item__price">
                    <span class="price ">
                      $ 11.99 </span>
                    <span class="price-info">user/month</span>
                  </div>

                  <div class="plans-item__trial">14 day free trial</div>

                  <div class="plans-item__features-ttl">Features</div>
                  <ul class="plans-item__features">
                    <li>Instantly see which LinkedIn profiles are already in your Zoho Recruit</li>
                    <li>100 email enrichments</li>
                    <li>Save LinkedIn profiles directly into your Zoho Recruit</li>

                  </ul>
                  <button class="buy_now" onClick={() => handleSelection("ELITE")}>Buy Now</button>
                </div>

              </div>
              <div class="plans-item">
                <div class="plans-item__content">
                  <div class="plans-item__bestseller">Classic</div>
                  <div class="plans-item__name">Professional Plan</div>
                  <div class="plans-item__price">
                    <span class="price ">
                      $ 21.99 </span>

                    <span class="price-info">user/month</span>
                  </div>

                  <div class="plans-item__trial">14 day free trial</div>

                  <div class="plans-item__features-ttl">Features</div>
                  <ul class="plans-item__features">
                    <li>Everything from STANDARD plan</li>
                    <li>500 bulk exports in Sales Nav Lists and LinkedIn Recruiter Projects</li>
                    <li>250 email enrichments</li>

                  </ul>
                  <button class="buy_now" onClick={() => handleSelection("CLASSIC")}>Buy Now</button>
                </div>

              </div>
              <div class="plans-item">
                <div class="plans-item__content">
                  <div class="plans-item__bestseller">Basic</div>
                  <div class="plans-item__name">Professional Plan</div>
                  <div class="plans-item__price">
                    <span class="price ">
                      $ 31.99 </span>

                    <span class="price-info">user/month</span>
                  </div>

                  <div class="plans-item__trial">14 day free trial</div>

                  <div class="plans-item__features-ttl">Features</div>
                  <ul class="plans-item__features">
                    <li>Everything from STANDARD plan</li>
                    <li>500 bulk exports in Sales Nav Lists and LinkedIn Recruiter Projects</li>
                    <li>250 email enrichments</li>

                  </ul>
                  <button class="buy_now" onClick={() => handleSelection("BASIC")}>Buy Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        {selectedPaymentPlan != null && <PaymentDetail paymentPlan={selectedPaymentPlan} paymentData={selectedPaymentData} resetSelectedPlan={setSelectedpaymentPlan}/>}

      </section>
    </>
  )
}