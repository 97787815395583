import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { LoginJPG, MainLOGO, signup } from '../../assets/images/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState("");
    const [state, setState] = useState(null);

    // Check if user is already logged in and redirect
    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            navigate('/dashboard', { replace: true });
        }
    }, [navigate]);

    const toggleForm = (type) => {
        const container = document.querySelector('.container');
        container.classList.toggle('active');
        setState(type);
    };

    async function handleLogin(event) {
        event.preventDefault();
        try {
            const payload = { username, password, role };
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/user/login`, payload);
            if (response && response.data && response.data.status) {
                localStorage.setItem('user', JSON.stringify(response.data));
                setTimeout(() => {
                    navigate('/dashboard', { replace: true });
                }, 1500);
            } else {
                toast.error(response.data.msg);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        setEmail("");
        setRole("");
        setPassword("");
        setConfirmPassword("");
        setUsername("");
    }, [state]);

    async function handleSubmit(event) {
        try {
            event.preventDefault();
            if (password == "" || password !== confirmPassword) {
                alert(password == "" ? "Password is blank" : "Password didn't match");
                return;
            }
            const payload = { email, username, password, role };
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/user/create`, payload);

            if (response && response.data && response.data.status) {
                localStorage.setItem('user', JSON.stringify(response.data.user));
                toast.success("User created!");
                setTimeout(() => {
                    navigate('/dashboard', { replace: true });
                }, 1500);
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="main-login">
            <section>
                <div className="container">
                    <div className="user signinBx">
                        <div className="imgBx"><img src={LoginJPG} alt="" /></div>
                        <div className="formBx">
                            <form >
                                <img src={MainLOGO} alt="" className="login_logo" />
                                <h2>Sign In</h2>
                                <input type="text" name="" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                                <input type="password" name="" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                <select name="userRole" className="role_select" onChange={(e) => setRole(e.target.value)} defaultValue="">
                                    <option value="" disabled>Login as</option>
                                    <option value="short">Short Term</option>
                                    <option value="long">Long term</option>
                                </select>
                                <button className="login_button" onClick={handleLogin}>Login</button>
                                <div className="signup">
                                    Don't have an account?
                                    <div onClick={() => toggleForm('signup')}>Sign Up.</div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="user signupBx">
                        <div className="formBx">
                            <form >
                                <img src={MainLOGO} className="login_logo" alt="" />
                                <h2>Create an account</h2>
                                <input type="text" name="" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                                <input type="email" name="" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                                <input type="password" name="" placeholder="Create Password" onChange={(e) => setPassword(e.target.value)} />
                                <input type="password" name="" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
                                <select name="userRole" className="role_select" onChange={(e) => setRole(e.target.value)} defaultValue="">
                                    <option value="" disabled>Signup as</option>
                                    <option value="short">Short Term</option>
                                    <option value="long">Long term</option>
                                </select>
                                <button className="login_button" onClick={handleSubmit}>Submit</button>
                                <div className="signup">
                                    Already have an account?
                                    <div onClick={() => toggleForm('login')}>Sign In.</div>
                                </div>
                            </form>
                        </div>
                        <div className="imgBx">
                            <img src={signup} alt="" />
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </section>
        </div>
    );
}
